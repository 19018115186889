<template>
  <div class="login-wrap">
		<div class="login-wind">
			<div class="ms-desc">
				<div class="ms-desc-text ms-desc-top">欢迎登陆</div>
				<div class="ms-desc-text ms-desc-bot">智慧机联网管理平台</div>
			</div>
			<div class="ms-login" v-if="loginFormBox == 1">
				<div class="ms-login-top ms-img-h">
					<img class="ms-login-img" src="../../static/login/logo.png" alt="">
					<div class="ms-login-text">用户登录</div>
				</div>
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="0px"
					class="demo-ruleForm"
				>
					<el-form-item prop="username">
						<el-input class="input" v-model="ruleForm.username" 
						placeholder="请输入账号"></el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input
							class="input"
							type="password"
							placeholder="密码"
							v-model="ruleForm.password"
						></el-input>
					</el-form-item>
					<div class="loginBetween">
						<el-checkbox class="loginSession" v-model="checked">记住密码</el-checkbox>
						<span class="userRegister loginSession" @click="handleUserBtn">新用户注册</span>
					</div>
					<div class="login-btn">
						<el-button class="btn" type="success" @click="handleLogin">登录</el-button>
					</div>
				</el-form>
			</div>
			<!-- 验证 -->
			<div class="ms-verify ms-login" v-else-if="loginFormBox == 2">
				<div class="ms-login-top">
					<img class="ms-login-img" src="../../static/login/logo.png" alt="">
					<div class="ms-login-text">验证</div>
				</div>
				<el-form
					:model="verifyForm"
					:rules="verifyRules"
					ref="ruleForm"
					label-width="0px"
					class="demo-ruleForm"
				>
					<el-form-item prop="username">
						<el-input
						class="input"
						v-model="verifyForm.username" 
						placeholder="真实姓名"></el-input>
					</el-form-item>
					<el-form-item prop="mobileno">
						<el-input
							class="input"
							placeholder="手机号"
							v-model="verifyForm.mobileno"
						></el-input>
					</el-form-item>
					<el-form-item prop="email">
						<el-input
							v-model="verifyForm.email"
							class="input"
							placeholder="邮箱">
						</el-input>
					</el-form-item>
					<div class="login-btn">
						<el-button class="btn" type="success" @click="handleVerifyLogin">验证</el-button>
						<el-button class="btn btnReturn" @click="handleVerifyRev">返回</el-button>
					</div>
				</el-form>
			</div>
			<!-- 注册 -->
			<div class="ms-login ms-login" v-else-if="loginFormBox == 3">
				<div class="ms-login-top">
					<img class="ms-login-img" src="../../static/login/logo.png" alt="">
					<div class="ms-login-text">注册</div>
				</div>
				<el-form
					:model="regisForm"
					:rules="regisRules"
					ref="ruleForm"
					label-width="0px"
					class="demo-ruleForm"
				>
					<el-form-item prop="username">
						<el-input class="input" 
						v-model="regisForm.username" 
						placeholder="请输入账号"></el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input
							class="input"
							type="password"
							placeholder="密码"
							v-model="regisForm.password"
						></el-input>
					</el-form-item>
					<el-form-item prop="repassword">
						<el-input
							class="input"
							type="password"
							placeholder="确认密码"
							v-model="regisForm.repassword"
						></el-input>
					</el-form-item>
					<div class="login-btn">
						<el-button class="btn" type="success" @click="handleRegistLogin">注册</el-button>
						<el-button class="btn btnReturn" @click="handleRegistRev">返回</el-button>
					</div>
				</el-form>
			</div>
		</div>
  </div>
</template>
<script>
import {
  EquipmentLogin,
	VerifyUser,
	RegisterUser,
} from "@/api/user.js"; //页面接口
import { encryptDes, decryptDes } from '@/common/js/des.js'
export default {
  name: "Login",
  data() {
    return {
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
			verifyRules:{
				username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
				mobileno: [
					{ required: true, message: '请输入手机号', trigger: 'blur' }
				],
				email: [
					{ required: true, message: '请输入邮箱', trigger: 'blur' }
				]
			},
			regisRules:{
				username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        repassword: [{ required: true, message: "请确认密码", trigger: "blur" }],
			},
      loading: false,
			checked:false,
			loginFormBox:1,
			verifyForm: {
        username: "",
        mobileno: "",
        email: "",
      },
			regisForm: {
        username: "",
        password: "",
				repassword:"",
      },
			UserNo:null,
    };
  },
	watch:{
	},
  methods: {
    handleLogin() {
			if (this.checked) {
				window.localStorage.setItem("username", encryptDes(this.ruleForm.username,'engo1234'))
				window.localStorage.setItem('password', encryptDes(this.ruleForm.password,'engo1234'));
			}else{
				localStorage.removeItem('password')
			}
			if(this.ruleForm.username&&this.ruleForm.password){
				EquipmentLogin({
					userName: encryptDes(this.ruleForm.username, 'engo1234'),
					passWord: encryptDes(this.ruleForm.password, 'engo1234')
				})
        .then((res) => {
          let obj = res.Content;
          if(res.Code==-1) {
            this.$notify.info({
              title:'消息',
              message: '登录失败'
            });
            return;
          }
			this.$message({
				duration: 1000,
				message: "登录成功！",
				type: "success",
			});
			sessionStorage.setItem("token",encryptDes(obj.LoginToken,'comencry1234'))//token加密
			sessionStorage.setItem("agencyId", obj.AgencyId);
			sessionStorage.setItem("BgColorType", obj.BgColorType);
			sessionStorage.setItem("UserNo", obj.UserNo);
			sessionStorage.setItem("MenuTree", JSON.stringify(obj.MenuTreeList));
			sessionStorage.setItem("User", encryptDes(obj.RealName,'engo1234'));
			this.$router.push("/ProductionManagementKanban")
        })
        .catch((err) => {

        })
			}else{
				this.$message.error("用户名和密码错误")
			}
    },
		// 新用户注册
		handleUserBtn(){
			this.loginFormBox = 2;
			this.verifyForm.username = ""
			this.verifyForm.mobileno = ""
			this.verifyForm.email = ""
		},
		// 验证
		handleVerifyLogin(){
			if (this.verifyForm.username === '') {
				this.$notify.info({
					title: '消息',
					message: '真实姓名不能为空'
				});
				return;
			}else if (!this.sendMobile() || !this.sendEmail()) {
				return;
			} else {
				VerifyUser({
						Realname:this.verifyForm.username,
						Email:this.verifyForm.email,
						MobileNo:this.verifyForm.mobileno
				}).then(res => {
						let obj = res.Content;
						if(res.Code == -1){
							this.$notify.info({
								title: '消息',
								message: obj
							});
							return;
						}

						this.loginFormBox = 3;
						this.regisForm.username = '';
						this.regisForm.password = '';
						this.regisForm.repassword = '';
						this.UserNo = obj;//接收返回用户信息

						this.$notify.info({
							title: '消息',
							message: "验证通过"
						});
				}).catch(err => {
						this.$notify.info({
							title: '消息',
							message: err//'用户验证失败'
						});
				});
			}
		},
		//验证手机号码部分
		sendMobile(){
			var reg=11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
			if(this.verifyForm.mobileno==''){
				this.$notify.info({
					title: '消息',
					message: '请输入手机号码'
				});
				return false;
			}else if(!reg.test(this.verifyForm.mobileno)){
				this.$notify.info({
					title: '消息',
					message: '手机格式不正确'
				});
				return false;
			}
			return true;
		},
		//邮箱验证
		sendEmail(){
			var regEmail= /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
			if(this.verifyForm.email==''){
				this.$notify.info({
					title: '消息',
					message: '请输入邮箱'
				});
				return false;
			}else if(!regEmail.test(this.verifyForm.email)){
				this.$notify.info({
					title: '消息',
					message: '邮箱格式不正确'
				});
				return false;
			}
			return true;
		},
		//验证注册UserName
		sendRegisterUserName(){
			var reg= /^(?!(\d+)$)[a-zA-Z\d_]{4,20}$/;
			if(this.regisForm.username==''){
				this.$notify.info({
					title: '消息',
					message: '请输入用户名'
				});
				return false;
			}else if(!reg.test(this.regisForm.username)){
				this.$notify.info({
					title: '消息',
					message: '用户名格式不正确，账号长度4-20，可包括数字、字母、下划线'
				});
				return false;
			}
			return true;
		},
		handleVerifyRev(){
			this.loginFormBox = 1;
		},
		// 注册
		handleRegistLogin(){
			if (this.regisForm.username === '' || this.regisForm.password === '') {
				this.$notify.info({
					title: '消息',
					message: '用户名或密码不能为空'
				});
				return;
			} else {
				if (this.regisForm.repassword === '') {
					this.$notify.info({
						title: '消息',
						message: '请输入确认密码'
					});
					return;
				} else if (this.regisForm.repassword !== this.regisForm.password) {
					this.$notify.info({
						title: '消息',
						message: '两次输入密码不一致!'
					});
					return;
				}else if (!this.sendRegisterUserName()) {
					return;
				}

				RegisterUser({
					userNo: this.UserNo,
					userCode: this.regisForm.username,
					userPass: this.regisForm.password,
				}).then(res => {
						let obj = res.Content;
						if(res.Code == -1){
							this.$notify.info({
								title: '消息',
								message: obj//'注册失败'
							});
							return;
						}

						this.loginFormBox = 1

						this.$notify.info({
							title: '消息',
							message: obj
						});
				}).catch(err => {
						this.$notify.info({
							title: '消息',
							message: err//'注册失败'
						});
				});
			}
		},
		handleRegistRev(){
			this.loginFormBox = 2;
		},
  },
  created() {
    let that = this;
    document.onkeydown = function (e) {
      e = window.event || e;
      if (
        that.$route.path == "/login" &&
        (e.key == "Enter" || e.key == "enter")
      ) {
        //验证在登录界面和按得键是回车键enter
        that.handleLogin(); //登录函数
      }
    };

  },
	mounted() {
		// 登录 第一次没缓存 解密报错
		let useNam = localStorage.getItem('username');
		let pasWord = localStorage.getItem('password');
		let username = '';
		let password = '';
		if (useNam) {
			username = decryptDes(useNam,'engo1234');
		}
		if (pasWord) {
			password = decryptDes(pasWord,'engo1234');
		}
		if (username && password) {
			this.ruleForm.username = username;
			this.ruleForm.password = password;
			this.checked = true;
		}
	},
};
</script>
<style lang="scss" scoped>
// @import '@/assets/mixin.scss';
.loginSession{
	margin-top: 10px;
}
.loginBetween{
	display: flex;
	justify-content: space-between
}
.userRegister{
	color: #666666;
	font-size: 14px;
	margin-left: 20px;
	cursor: pointer;
}
@media only screen and (max-aspect-ratio: 1920/920 ) {
	.login-wrap {
	  background-color: #42B983;
	  width: 100%;
	  height: 100vh;
	  background-size: 100% 100%;
	  background-position: center center;
		position: relative;
		background: url("../../static/login/bg.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
		.login-wind{
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 1010px;
			height: 610px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			background: transparent;
			.ms-desc{
				width: 560px;
				height: 100%;
				// background-color: rgba(19, 124, 87, 0.8);
				background:linear-gradient(to right,rgba(70, 164, 126, 0.8) 0%, rgba(27, 128, 90, 0.8) 50%, rgba(14, 118, 82, 0.8) 90%);
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
				.ms-desc-text{
					margin-left: 100px;
					font-size: 36px;
					color: #fff;
				}
				.ms-desc-top{
					position: absolute;
					left: 0;
					top: 40%;
				}
				.ms-desc-bot{
					position: absolute;
					left: 0;
					top: 53%;
				}
			}
			.ms-login {
				width: 450px;
				height: 100%;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
				background: #fff;
			}
		}
		.ms-login{
			.ms-img-h{
				height: 275px;
			}
			.ms-login-top{
				width: 100%;
				text-align: center;
				.ms-login-img{
					margin-top: 95px;
					display: inline-block;
					width: 112px;
					height: 66px;
					margin-bottom: 15px;
				}
				.ms-login-text{
					color: #000000;
					font-size: 30px;
				}
			}
			.demo-ruleForm{
				padding: 0 55px;
				.login-btn {
					margin-top: 50px;
					.btn {
						width: 350px;
						height: 45px;
						line-height:45px;
					}
					.btnReturn{
						margin: 10px 0 0;
					}
				}
				.input{
					margin-top: 15px;
					font-size: 14px;
				}
				.demo-check{
					margin-top: 10px;
				}
			}
		}
	}
}
@media only screen and (min-aspect-ratio: 1920/920 ) {
	.login-wrap {
	  background-color: #42B983;
	  width: 100%;
	  height: 100vh;
	  background-size: 100% 100%;
	  background-position: center center;
	  overflow: auto;
	  position: relative;
		background: url("../../static/login/bg.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
		.login-wind{
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 1010px;
			height: 61vh;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			background: transparent;
			.ms-desc{
				width: 560px;
				height: 100%;
				// background-color: rgba(44, 146, 109,0.3);
				background:linear-gradient(to right,rgba(70, 164, 126, 0.8) 0%, rgba(27, 128, 90, 0.8) 50%, rgba(14, 118, 82, 0.8) 90%);
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
				position: relative;
				.ms-desc-text{
					margin-left: 100px;
					font-size: 36px;
					color: #fff;
				}
				.ms-desc-top{
					position: absolute;
					left: 0;
					top: 40%;
				}
				.ms-desc-bot{
					position: absolute;
					left: 0;
					top: 53%;
				}
			}
			.ms-login {
				width: 450px;
				height: 100%;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
				background: #fff;
			}
		}
		.ms-login{
			.ms-img-h{
				height: 27.5vh;
			}
			.ms-login-top{
				width: 100%;
				text-align: center;
				.ms-login-img{
					margin-top: 95px;
					display: inline-block;
					width: 112px;
					height: 6.6vh;
					margin-bottom: 15px;
				}
				.ms-login-text{
					color: #000000;
					font-size: 30px;
				}
			}
			.demo-ruleForm{
				padding: 0 55px;
				.login-btn {
					margin-top: 50px;
					.btn {
						width: 350px;
						height: 4.5vh;
						line-height:4.5vh;
					}
					.btnReturn{
						margin: 10px 0 0;
					}
				}
				.input{
					margin-top: 15px;
					font-size: 14px;
				}
				.demo-check{
					margin-top: 10px;
				}
			}
		}
	}
}
</style>
<style lang="scss">
// .login-btn .el-button{
// 	color: #fff;
	// background: #1cb973;
	// border: 1px solid #1cb973;
// }
.login-btn .el-button:focus, 
.login-btn .el-button:hover{
	color: #fff;
	border-color: 1px solid rgba(28, 185, 115, 0.8);
	background-color: rgba(28, 185, 115, 0.8)
}
.loginSession .el-checkbox__input.is-checked + .el-checkbox__label{
	color: #666666;
}
.loginSession.el-checkbox{
	color: #666666!important;
}
</style>